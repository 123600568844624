<template>
  <span>
    <span v-if="deleted && forList" class="deleted">（使用不可）</span>
    <span v-if="deleted && !forList" class="deleted">使用不可</span>
    <span v-if="!deleted && !forList">使用可</span>
  </span>
</template>

<script>
export default {
  props: {
    deleted: {
      type: Boolean,
    },
    forList: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped lang="scss">
span.deleted {
  color: red;
  font-weight: bold;
}
</style>
