<template>
  <th @click="onClicked">
    <div class="sort" :class="{
      asc: sortPropMatched && sortType === 'ASC',
      desc: sortPropMatched && sortType === 'DESC'
    }"><slot></slot></div>
  </th>
</template>

<script>
export default {
  props: {
    prop: String,
    searchContext: Object
  },
  computed: {
    form(){
      return this.searchContext.getForm();
    },
    sortPropMatched(){
      if(this.form == null || this.form._sort == null) return false;
      return this.form._sort.prop === this.prop;
    },
    sortType(){
      if(this.form == null || this.form._sort == null) return null;
      return this.form._sort.type;
    }
  },
  methods: {
    onClicked(){
      this.searchContext.onSortChanged(this.prop);
    }
  }
};
</script>

<style scoped lang="scss">
.sort {
  background-image: url(./th-sort-images/sort.png);
  background-repeat: no-repeat;
  background-position: right center;
  cursor: pointer;
  padding: 1px 0;
  &:hover {
    color: #337ab7;
  }
}
.asc {
  background-image: url(./th-sort-images/sort_asc.png);
}
.desc {
  background-image: url(./th-sort-images/sort_desc.png);
}


</style>
