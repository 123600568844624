export let ExtEnum = null;

export function loadExtEnum(loader) {
  if (ExtEnum != null) {
    return Promise.resolve();
  }
  return loader().then((data) => {
    ExtEnum = {};
    for (let key in data) {
      let values = data[key];
      let type = {
        values: () => values,
      };
      for (let e of values) {
        type[e.id] = e;
      }
      ExtEnum[key] = type;
    }
  });
}
