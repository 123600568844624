<template>
  <span class="input-text" :class="{'has-error': error}">
    <input
      ref="input"
      type="text"
      class="form-control"
      :class="inputClass"
      :style="inputStyle"
      :value="value"
      v-bind="$attrs"
      v-on="inputListeners"
    />
    <ErrorTooltip :target="$refs.input" v-bind="errorTooltipAttrs" />
  </span>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  mixins: [
    InputMixin
  ],
  computed: {
    inputListeners: function() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          this.$emit('input', event.target.value);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.input-text {
  &.has-error input {
    background-color: #fdd;
    border: solid 1px red;
  }
}
</style>
