<template>
  <span class="input-select" :class="{'has-error': error}">
    <select
      ref="input"
      type="text"
      class="form-control"
      :class="inputClass"
      :style="inputStyle"
      v-bind="$attrs"
      v-on="inputListeners"
    >
      <option v-if="this._nullOption"
        :value="this._nullOption.value"
        :label="this._nullOption.label"
        :selected="this._nullOption.selected"/>
      <option v-for="(option, index) in this._inputOptions" :key="index"
        :value="option.value"
        :label="option.label"
        :selected="option.selected"/>
    </select>
    <ErrorTooltip :target="$refs.input" v-bind="errorTooltipAttrs" />
  </span>
</template>

<script>
import InputMixin from './InputMixin'
import OptionsMixin from './OptionsMixin'
import {config} from '../../service';

export default {
  mixins: [
    InputMixin,
    OptionsMixin
  ],
  props: {
    nullOption: {
      type: [Boolean, String],
      default: true
    }
  },
  computed: {
    inputListeners: function() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          this.$emit('input', this.ops.toValue(event.target.value, this._options));
        }
      });
    },
    _nullOption: function(){
      if(this.nullOption === false){
        return null;
      }else if(this.nullOption === true){
        return {
          value: null,
          label: config.get('input.select.defaultNullLabel', '---'),
          selected: this.value == null
        };
      }else{
        return {
          value: null,
          label: this.nullOption,
          selected: this.value == null
        };
      }
    },
  }
};
</script>

<style scoped lang="scss">
.input-select {
  &.has-error select {
    background-color: #fdd;
    border: solid 1px red;
  }
}
</style>
