import {Api, RouteConfigBuilder} from "@/calico-vue/service";
import store from "@/store";

const basePath = "/signup";

/**
 * routes
 */
const Start = () => import(/* webpackChunkName: "signup" */ "./Start");
const Email = () => import(/* webpackChunkName: "signup" */ "./Email");
const Confirm = () => import(/* webpackChunkName: "signup" */ "./Confirm");
const Register = () => import(/* webpackChunkName: "signup" */ "./Register");
const Completed = () => import(/* webpackChunkName: "signup" */ "./Completed");

const Routes = new RouteConfigBuilder(basePath, {
  allowAll: true,
});
export const routes = [
  Routes.baseRedirect("start"),
  Routes.component("start", Start),
  Routes.component("email", Email),
  Routes.component("confirm", Confirm),
  Routes.component("register", Register),
  Routes.component("completed", Completed),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  siteAuth(form) {
    return api.execute("site_auth", form);
  },
  sendConfirmationCode(email) {
    const form = { email: email, registrationToken: store.state.registrationToken };
    return api.execute("send_confirmation_code", form);
  },
  confirmCode(code) {
    const form = { confirmationCode: code, registrationToken: store.state.registrationToken };
    return api.execute("confirm_code", form);
  },
  getSignUpInfo() {
    if (!store.state.registrationToken) {
      return new Promise(resolve => resolve(null));
    }
    const form = { registrationToken: store.state.registrationToken };
    return api.execute("get_sign_up_info", form);
  },
  register(form) {
    form.registrationToken = store.state.registrationToken;
    return api.execute("register", form);
  },
  addSite(form) {
    form.registrationToken = store.state.registrationToken;
    return api.execute("add_site", form);
  }
};
