export const BUTTON_TYPES = {
  default: {
    label: null,
    type: "button",
    classes: ["btn"],
    styles: null,
    glyphicon: null,
  },
  search: {
    label: "検索する",
    type: "submit",
    classes: ["btn-primary", "font-weight-bold"],
  },
  back: {
    label: "戻る",
    glyphicon: "arrow-left",
  },
  cancel: {
    classes: ["btn-default", "font-weight-bold"],
    label: "キャンセル",
  },
  close: {
    classes: ["btn-default", "font-weight-bold"],
    label: "閉じる",
  },
  new: {
    label: "新規追加",
    classes: ["btn-primary", "font-weight-bold"],
  },
  bulk: {
    label: "一括登録",
    classes: ["btn-primary", "font-weight-bold"],
  },
  add: {
    label: "追加する",
    classes: ["btn-primary", "font-weight-bold"],
  },
  edit: {
    label: "編集する",
    classes: ["btn-primary", "font-weight-bold"],
  },
  save: {
    label: "登録する",
    classes: ["btn-primary", "font-weight-bold"],
  },
  delete: {
    label: "削除する",
    classes: ["btn-warning", "font-weight-bold"],
  },
  success: {
    classes: ["btn-success", "font-weight-bold"],
  },
  warning: {
    classes: ["btn-warning", "font-weight-bold"],
  },
  primary: {
    classes: ["btn-primary", "font-weight-bold"],
  },
  "default-xs": {
    classes: ["btn-default", "btn-xs"],
  },
  "primary-xs": {
    classes: ["btn-primary", "btn-xs"],
  },
  "warning-xs": {
    classes: ["btn-warning", "btn-xs"],
  },
  "to-list": {
    label: "一覧へ",
    glyphicon: "list",
  },
  "to-login-form": {
    label: "ログイン画面に戻る",
    glyphicon: "arrow-left",
  },
  download: {
    glyphicon: "download-alt",
  },
  execute: {
    label: "実行",
    classes: ["btn-primary"],
    glyphicon: "flash",
  },
};
