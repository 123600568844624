import find from "sugar/array/find";

export let STRATEGIES = {
  default: {
    toInputValue(option) {
      if (option == null) {
        return null;
      } else if (option.id === undefined) {
        return option;
      } else {
        return option.id;
      }
    },
    toLabel(option) {
      if (option == null) {
        return null;
      } else if (option.name === undefined) {
        return option;
      } else {
        return option.name;
      }
    },
    valueEquals(value1, value2) {
      return (
        String(this.toInputValue(value1)) === String(this.toInputValue(value2))
      );
    },
    isSelected(option, value) {
      return this.valueEquals(option, value);
    },
    toValue(inputValue, options) {
      let selected = find(options, (option) => {
        return this.isSelected(option, inputValue);
      });
      return selected == null ? null : this.toInputValue(selected);
    },
  },
  extEnum: {
    toInputValue(option) {
      return option == null ? null : option.id;
    },
    toLabel(option) {
      return option == null ? null : option.name;
    },
    valueEquals(value1, value2) {
      return this.toInputValue(value1) === this.toInputValue(value2);
    },
    isSelected(option, value) {
      return this.valueEquals(option, value);
    },
    toValue(inputValue, options) {
      if (inputValue == null || inputValue === "") {
        return null;
      }
      return find(options, (option) => {
        return String(this.toInputValue(option)) === String(inputValue);
      });
    },
  },
};

export function setStrategy(key, strategy) {
  STRATEGIES[key] = strategy;
}
