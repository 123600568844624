import {Notification} from "uiv";
import isObject from "sugar/object/isObject";

export const announce = {
  _opts: {},
};

export function extendAnnounce(types) {
  if (types == null) return;
  for (const key in types) {
    const opts = types[key];
    announce._opts[key] = opts;
    announce[key] = createNotifyFunction(opts);
  }
}

function createNotifyFunction(typeOpts) {
  return function (arg) {
    let argOpts = {};
    if (arg != null) {
      argOpts = isObject(arg) ? arg : { content: String(arg) };
    }
    let defaultOpts = announce._opts.default || {};
    let opts = Object.assign({}, defaultOpts, typeOpts, argOpts);
    //glyphicon support
    if (opts.glyphicon != null) {
      opts.icon = "glyphicon glyphicon-" + opts.glyphicon;
    }
    return Notification.notify(opts);
  };
}

// https://uiv.wxsm.space/components/notification.html#props
export const DEFAULT_TYPES = {
  default: {
    type: "info",
    title: undefined,
    content: undefined,
    html: false,
    glyphicon: undefined, //独自対応
    duration: 3000,
    placement: undefined,
    customClass: undefined,
    offset: undefined,
    offsetX: undefined,
    offsetY: undefined,
  },
};
