import {Api, RouteConfigBuilder} from "@/calico-vue/service";

const basePath = "/registration";

/**
 * routes
 */
const Input = () => import(/* webpackChunkName: 'registration' */
  process.env.VUE_APP_MODE_GAKKEN === 'true' ? "./gakken/Input" : "./official/Input");

const Routes = new RouteConfigBuilder(basePath, {
  allowAll: true,
});
export const routes = [
  Routes.baseRedirect("input"),
  Routes.component("input", Input),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  checkToken(token) {
    return api.execute("check_token", { token: token });
  },
  update(form) {
    return api.execute("update", { withName: false, ...form });
  },
  updateWithName(form) {
    return api.execute("update", { withName: true, ...form });
  }
};
