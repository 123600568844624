function defaultErrorSelector(vm, tokens) {
  if (vm.error == null || vm.error.fieldErrors == null) {
    return null;
  }
  return vm.error.fieldErrors[tokens.join(".")];
}

export default {
  install(Vue, errorSelector = defaultErrorSelector) {
    Vue.mixin({
      methods: {
        $error(...tokens) {
          return errorSelector(this, tokens);
        },
      },
    });
  },
};
