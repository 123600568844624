/*
 * このファイルは自動生成されました。
 * 変更する場合はプロパティファイルのほうを修正してください。
 */
export const CONFIG = {
  vue: {
    config: {
      productionTip: false,
    },
    errorHandler: {
      report: true,
      reportPath: "/endpoint/system/jserror/report",
      reportThrottleMs: 1000,
    },
  },
  sessionStorage: {
    prefix: "jet-cbt-",
    searchFormKey: "search-form",
  },
  api: {
    rootPath: "/endpoint",
  },
  input: {
    select: {
      defaultNullLabel: "---",
    },
  },
  modal: {
    defaultAttrs: {
      footer: false,
    },
  },
  filter: {
    date: {
      format: {
        year: "YYYY",
        month: "YYYY/MM",
        date: "YYYY/MM/DD(dd)",
        jdate: "YYYY\u5e74M\u6708D\u65e5",
        time: "HH:mm",
        datetime: "YYYY/MM/DD(dd) HH:mm",
        timestamp: "YYYY/MM/DD HH:mm:ss",
      },
    },
    currency: {
      prefix: "",
      postfix: "\u5186",
    },
  },
};
