import {safeAccess} from "../service";

const CACHE = {};

class Config {
  constructor() {
    this.data = {};
  }

  get(key, defaultValue = null) {
    if (CACHE[key] !== undefined) {
      return CACHE[key];
    }
    const val = safeAccess(this.data, key);
    if (val === undefined) {
      return defaultValue;
    }
    CACHE[key] = val;
    return val;
  }

  setData(data) {
    this.data = data;
  }
}

const config = new Config();

export default config;
