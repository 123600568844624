import axios from "axios";
import {safeAccess} from "@/calico-vue/service";

export function createErrorHandler(config) {
  const cfg = config.get("vue.errorHandler", {});
  return function (err) {
    if (cfg.report && err != null) {
      reportError(err, cfg.reportPath, cfg.reportThrottleMs);
    }
    throw err;
  };
}

let throttled = false;

function reportError(err, path, throttleMs) {
  //サーバレスポンス500のエラーはスルー
  if ((safeAccess(err, "response.status") || 0) / 100 === 5) {
    console.log(88, err);
    return;
  }

  //throttled
  if (throttled) return;
  if (throttleMs != null) {
    throttled = true;
    setTimeout(function () {
      throttled = false;
    }, throttleMs);
  }

  //report to server
  try {
    axios
      .create({
        withCredentials: true,
      })
      .post(path, createReportParam(err));
  } catch (ex) {
    0;
  }
}
function createReportParam(err) {
  return {
    location: location.href,
    userAgent: navigator.userAgent,
    exception: err.stack != null ? err.stack : err.toString(),
  };
}
