export default {
  bind: function (el, binding) {
    apply(el, binding.value);
  },
  update: function (el, binding) {
    if (!isChanged(binding)) return;
    apply(el, binding.value);
  },
};

function isChanged(binding) {
  return binding.value !== binding.oldValue;
}

function apply(el, value) {
  if (value === false) {
    removeCreateLabel(el);
  } else {
    appendCreateLabel(el);
  }
}

function findLabel(el) {
  return el.querySelector("span.req-label");
}

function appendCreateLabel(el) {
  if (findLabel(el) == null) {
    let label = document.createElement("span");
    label.classList.add("req-label");
    el.append(label);
  }
}

function removeCreateLabel(el) {
  let label = findLabel(el);
  if (label != null) {
    el.removeChild(label);
  }
}
