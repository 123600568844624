import {Api, RouteConfigBuilder} from "@/calico-vue/service";
import {getBasePath} from "@/app/menu-tree";

const menuId = 'jet.cbt.ta.exam';
const basePath = getBasePath(menuId);

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'exam_management' */
  process.env.VUE_APP_MODE_GAKKEN === 'true' ? "./gakken/Index" : "./official/Index");

export const examTitle = process.env.VUE_APP_MODE_GAKKEN === 'true' ? '試験管理': 'JET CBTテスト管理';
const Routes = new RouteConfigBuilder(basePath, {
  title: examTitle,
  menuId,
  basePath
});
export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  getGrades() {
    return api.execute("select_grades");
  },
  getSites(examTermId) {
    return api.execute("select_sites", {examTermId});
  },
  getExamTerms() {
    return api.execute("select_exam_terms");
  },
  getExaminees(siteId, examTermId) {
    return api.execute("select_examinees", {siteId, examTermId});
  },
  getExaminee(siteId, examineeId) {
    return api.execute("fetch_examinee", {siteId, examineeId});
  },
  getAuthCode(siteId, examTermId) {
    return api.execute("get_auth_code", {siteId, examTermId});
  },
  resetExam(siteId, examineeId) {
    console.log(siteId, examineeId);
    return api.execute("reset_exam", {siteId, examineeId});
  },
  updateExamGrade(siteId, examineeId, examGradeCode) {
    return api.execute("update_exam_grade", {siteId, examineeId, examGradeCode});
  },
  updateExamGrades(siteId, examGradeCodes) {
    return api.execute("update_exam_grades", {siteId, examGradeCodes});
  },
  updateExamniee(siteId, examineeId, examnieeCorrect) {
    return api.execute("update_examinee", {siteId, examineeId, ...examnieeCorrect});
  },
  unlockAccount(siteId, examineeId) {
    return api.execute("unlock_account", {siteId, examineeId});
  }
};
