let TYPES = {
  default: {
    label: null,
    type: "button",
    classes: ["btn", "btn-default"],
    styles: {},
    glyphicon: null,
    handle: null,
  },
};

export function setButtonTypes(types) {
  TYPES = types;
}

export default {
  bind: function (el, binding) {
    applyToElement(el, TYPES.default);
    if (binding.arg != null) {
      applyToElement(el, TYPES[binding.arg]);
    }
    if (binding.modifiers.small) {
      el.classList.add("btn-sm");
    }
    if (binding.modifiers.large) {
      el.classList.add("btn-lg");
    }
  }
};

function applyToElement(el, type) {
  if (type == null) return;

  console.log("applyToElement", el, type);
  if (type.label != null && (el.textContent == null || el.textContent === "")) {
    el.innerText = type.label;
    console.log("set label", el, type.label);
  }
  if (type.type != null) {
    el.setAttribute("type", type.type);
  }
  if (type.classes != null) {
    el.classList.add(...type.classes);
  }
  if (type.styles != null) {
    for (let key in type.styles) {
      if (el.style[key] == null || el.style[key] === "") {
        el.style[key] = type.styles[key];
      }
    }
  }
  if (type.glyphicon != null) {
    let icon = document.createElement("i");
    icon.classList.add("glyphicon", "glyphicon-" + type.glyphicon);
    icon.setAttribute("aria-hidden", "true");
    icon.style.marginRight = "0.2em";
    el.prepend(icon);
  }
  if (type.handle != null) {
    type.handle(el);
  }
}
