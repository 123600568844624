<template>
  <Tooltip v-if="error"
    :text="errorMessage"
    :target="target"
    :placement="placement"
    custom-class="error-tooltip" />
</template>

<script>
import {Tooltip} from 'uiv';
import isArray from 'sugar/object/isArray';

export default {
  components: {
    Tooltip
  },
  props: {
    error: [String, Array],
    target: null,
    placement: {
      type: String,
      default: 'right'
    }
  },
  computed: {
    errorMessage: function(){
      if(this.error == null) return null;
      if(isArray(this.error)){
        return this.error.join('<br>');
      }
      return this.error;
    }
  }
};
</script>
