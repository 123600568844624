import {Api, RouteConfigBuilder} from "@/calico-vue/service";
import {getBasePath} from "@/app/menu-tree";

const menuId = "jet.cbt.ta.email";
const basePath = getBasePath(menuId);

/**
 * routes
 */
const Input = () => import(/* webpackChunkName: 'useraccount' */ "./Input");
const Confirm = () => import(/* webpackChunkName: "useraccount" */ "./Confirm");

const Routes = new RouteConfigBuilder(basePath, {
  title: "メールアドレス変更",
  menuId,
  basePath,
});

export const routes = [
  Routes.baseRedirect("input"),
  Routes.component("input", Input),
  Routes.component("confirm", Confirm),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  confirmCode(code) {
    return api.execute("confirm_code", { code: code });
  },
  sendConfirmationCode(email) {
    return api.execute("send_confirmation_code", { email: email });
  },
};
