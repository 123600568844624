import ErrorTooltip from "./ErrorTooltip";

export default {
  components: {
    ErrorTooltip,
  },
  props: {
    value: null,
    error: [String, Array],
    errorPlacement: String,
    inputClass: null,
    inputStyle: null,
    defaultFocus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errorTooltipAttrs: function () {
      return {
        error: this.error,
        placement: this.errorPlacement,
      };
    },
  },
  mounted() {
    if (this.defaultFocus) {
      this.focus();
    }
  },
  methods: {
    focus() {
      if (this.$refs.input == null) return;
      this.$refs.input.focus();
    },
  },
};
