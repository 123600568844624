<template>
  <span class="input-time" :class="{'has-error': error}">
    <input
      ref="input"
      type="time"
      class="form-control"
      :class="inputClass"
      :style="inputStyle"
      :value="inputValue"
      v-bind="$attrs"
      v-on="inputListeners"
    />
    <span class="glyphicon glyphicon-remove btn-remove" @click="clear()"></span>
    <ErrorTooltip :target="$refs.input" v-bind="errorTooltipAttrs" />
  </span>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  mixins: [
    InputMixin
  ],
  props: {
    toInputValue: Function,
    toValue: Function,
  },
  computed: {
    inputListeners: function() {
      const vm = this;
      return Object.assign({}, this.$listeners, {
        input: event => {
          vm.$emit('input', this._toValue(event.target.value));
        }
      });
    },
    inputValue: function(){
      return this._toInputValue(this.value);
    },
    _toInputValue: function(){
      return this.toInputValue || DEFAULT_FUNC.toInputValue;
    },
    _toValue: function(){
      return this.toValue || DEFAULT_FUNC.toValue;
    },
  },
  methods: {
    clear() {
      this.$emit('input', null)
    }
  }
};

const normalize = (value) => {
  if(value == null) return null;
  const time = value.split(':').map(v => parseInt(v));
  if (time.length < 2 || time.includes(t => t == null || Number.isNaN(t))) return null;
  if (time[0] < 0) {
    time[0] = Math.abs(time[0]);
  }
  if (time[1] < 0) {
    time[1] = Math.abs(time[1]);
  }
  if (time[1] >= 60) {
    time[1] = 59;
  }
  return String(time[0]).padStart(2, '0') + ':' + String(time[1]).padStart(2, '0');
};

const DEFAULT_FUNC = {
  toInputValue(value) {
    return normalize(value);
  },
  toValue(inputValue) {
    return normalize(inputValue);
  }
};
</script>

<style scoped lang="scss">
.input-time {
  &.has-error input {
    background-color: #fdd;
    border: solid 1px red;
  }

  input[type="time"] {
    width: 105px;
    &::-webkit-calendar-picker-indicator {
      margin-inline-start: 0;
      margin-inline-end: 12px;
    }
  }

  .btn-remove {
    color: #999;
    margin-left: -25px;
    margin-right: 10px;
    &:hover {
      color: #ccc;
    }
  }
}
</style>
