import {Api, RouteConfigBuilder} from "@/calico-vue/service";

const basePath = "/password";

/**
 * routes
 */
const Email = () => import(/* webpackChunkName: "password" */ "./Email");
const Reset = () => import(/* webpackChunkName: "password" */ "./Reset");

const Routes = new RouteConfigBuilder(basePath, {
  allowAll: true,
});
export const routes = [
  Routes.baseRedirect("email"),
  Routes.component("email", Email),
  Routes.component("reset", Reset),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  checkToken(token) {
    return api.execute("check_token", { token: token });
  },
  sendResetUrl(email) {
    return api.execute("send_reset_url", { email: email });
  },
  update(form) {
    return api.execute("update", form);
  }
};
