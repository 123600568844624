import Vue from "vue";
import {safeAccess} from "../safe-access/index";

export class DefaultAuthInfo {
  constructor() {
    this.authData = null;
  }

  setData(data) {
    this.authData = Vue.observable(data);
  }

  get loginId() {
    return safeAccess(this.authData, "loginId");
  }

  get authorities() {
    return safeAccess(this.authData, "authorities") || [];
  }

  get authenticated() {
    return safeAccess(this.authData, "authenticated") || false;
  }
}
