import {RouteConfigBuilder} from "@/calico-vue/service";
/**
 * routes
 */
import Login from "./Login"; //遅延ロードしない

const basePath = "/siteauth";

const Routes = new RouteConfigBuilder(basePath, {
  allowAll: true,
});
export const routes = [
  Routes.baseRedirect("login"),
  Routes.component("login", Login),
];
