import isFunction from "sugar/object/isFunction";

export class RouteConfigBuilder {
  constructor(basePath, defaultMeta = {}) {
    this.basePath = basePath;
    this.defaultMeta = defaultMeta;
  }

  component(path, component, meta = {}) {
    return {
      path: pathWithBase(path, this.basePath),
      component: component,
      meta: Object.assign({}, this.defaultMeta, meta),
    };
  }

  redirect(path, redirect, meta = {}) {
    return {
      path: pathWithBase(path, this.basePath),
      redirect: pathWithBase(redirect, this.basePath),
      meta: Object.assign({}, this.defaultMeta, meta),
    };
  }

  baseRedirect(redirect, meta = {}) {
    return {
      path: this.basePath,
      redirect: pathWithBase(redirect, this.basePath),
      meta: Object.assign({}, this.defaultMeta, meta),
    };
  }
}

function pathWithBase(path, basePath) {
  if (isFunction(path)) return path;
  //pathが'/'から始まる場合はbasePathを付けない
  if (path.startsWith("/")) {
    return path;
  }
  return basePath + "/" + path;
}
