export const TABLE_TYPES = {
  default: {
    label: null,
    classes: ["table", "table-bordered"],
    styles: null,
  },
  list: {
    classes: ["table-striped"],
  },
  info: {
    classes: null,
  },
};
