import {safeAccess} from "../";

export function safe(obj, key) {
  return safeAccess(obj, key);
}

export function id(obj) {
  return safeAccess(obj, "id");
}

export function name(obj) {
  return safeAccess(obj, "name");
}
