<template>
  <div class="panel panel-default">
    <div v-if="$slots.header || title" class="panel-heading">
      {{title}}
      <slot name="header"></slot>
    </div>
    <div class="panel-body">
      <slot></slot>
    </div>
    <div v-if="$slots.footer" class="panel-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
};
</script>

<style scoped lang="scss">
.panel-default {
  border-color: #c7c7c7;
}
</style>
