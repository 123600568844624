import isEqual from "sugar/object/isEqual";
import isString from "sugar/object/isString";

export default {
  bind: function (el, binding) {
    el.prepend(document.createElement("colgroup"));
    updateCols(el, binding);
  },
  update: function (el, binding) {
    if (!isChanged(binding)) return;
    updateCols(el, binding);
  },
};

function isChanged(binding) {
  return !isEqual(binding.value, binding.oldValue);
}

function updateCols(el, binding) {
  let colgroup = el.querySelector("colgroup");
  colgroup.innerHTML = "";
  for (let val of binding.value) {
    colgroup.append(createCol(val, getUnit(binding)));
  }
}

function getUnit(binding) {
  if (binding.modifiers.px) {
    return "px";
  }
  return "%";
}

function createCol(val, unit) {
  let col = document.createElement("col");
  if (val != null) {
    if (isString(val)) {
      col.style.width = val;
    } else {
      col.style.width = val + unit;
    }
  }
  return col;
}
