let TYPES = {
  default: {
    label: null,
    classes: ["table", "table-bordered"],
    styles: {},
    handle: null,
  },
};

export function setTableTypes(types) {
  TYPES = types;
}

export default {
  bind: function (el, binding) {
    applyToElement(el, TYPES.default);
    if (binding.arg != null) {
      applyToElement(el, TYPES[binding.arg]);
    }
    if (binding.modifiers.hover) {
      el.classList.add("table-hover");
    }
  },
};

function applyToElement(el, type) {
  if (type == null) return;

  if (type.label != null) {
    el.append(type.label);
  }
  if (type.classes != null) {
    el.classList.add(...type.classes);
  }
  if (type.styles != null) {
    for (let key in type.styles) {
      if (el.style[key] == null || el.style[key] === "") {
        el.style[key] = type.styles[key];
      }
    }
  }
  if (type.handle != null) {
    type.handle(el);
  }
}
