import {EventBus, SearchContext} from "../service";

export default function (
  router,
  path,
  query = null,
  hash = null,
  event = null
) {
  //query, hashに$eventが渡されている可能性があるので対応
  try {
    if (query instanceof MouseEvent) {
      event = query;
      query = null;
    } else if (hash instanceof MouseEvent) {
      event = hash;
      hash = undefined;
    }
  } catch (e) {
    0;
  }

  //hash復元
  if (hash === null || hash === false) {
    hash = undefined;
  } else if (hash === true) {
    const route = router.resolve({ path: path }).route;
    const raw = SearchContext.restoreSearchFormFromSessionStorage(route);
    if (raw != null) {
      hash = "#" + raw;
    } else {
      hash = undefined;
    }
  }

  //画面遷移 or 別タブ開く
  const params = { path: path, query: query, hash: hash };
  if (isWindowOpen(event)) {
    window.open(router.resolve(params).href, "_blank");
  } else {
    router.push(params).catch((err) => {
      if (
        err.message.match(/Avoided redundant navigation to current location/)
      ) {
        //同じURLに移動
        if (event != null) {
          //ユーザの操作による場合のみ
          EventBus.$emit("reload-router-view");
        }
      }
    });
  }
}

function isWindowOpen(event) {
  return event != null && (event.ctrlKey || event.metaKey);
}
