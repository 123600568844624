<template>
  <span class="input-checkboxes" :class="{'has-error': error}" ref="target">
    <label v-for="(option, index) in this._inputOptions" :key="index"
      class="form-check-label"
    >
      <input
        type="checkbox"
        class="form-check-input"
        :class="inputClass"
        :style="inputStyle"
        v-bind="$attrs"
        v-on="inputListeners"
        :value="option.value"
        :checked="option.selected"
      />
      <span>{{option.label}}</span>
    </label>
    <ErrorTooltip :target="$refs.target" v-bind="errorTooltipAttrs" />
  </span>
</template>

<script>
import InputMixin from './InputMixin';
import OptionsMixin from './OptionsMixin';

export default {
  mixins: [
    InputMixin,
    OptionsMixin
  ],
  props: {
    emptyToNull: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    inputListeners: function() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          let val = this.ops.toValue(event.target.value, this._options);
          let isInclude = (this.value || []).filter(e => this.ops.valueEquals(e, val)).length !== 0;
          //追加
          if(event.target.checked && !isInclude){
            let value = (this.value || []).concat([val]);
            this.$emit('input', value);
          }
          //削除
          if(!event.target.checked && isInclude){
            let value = (this.value || []).filter(e => !this.ops.valueEquals(e, val));
            this.$emit('input', this.emptyToNull && value.length === 0 ? null : value);
          }
        }
      });
    },
    _inputOptions: function(){
      return (this._options || []).map(option => {
        return {
          value: this.ops.toInputValue(option),
          label: this.ops.toLabel(option),
          selected: (this.value || []).some(e => this.ops.isSelected(option, e))
        };
      });
    }
  },
};
</script>

<style scoped lang="scss">
.input-checkboxes {
  display: inline-block;
  border: 1px solid #0000;
  border-radius: .5em;
  &.has-error {
    background-color: #fdd;
    border: solid 1px red;
  }

  label {
    display: inline-block;
    margin: .1em .1em .1em .1em;
    padding: .3em;
    cursor: pointer;
    border: 1px solid #0000;
    border-radius: .5em;
    &:hover {
      border: 1px solid #ddd;
      background-color: #fafafa
    }

    span {
      margin: 0 .2em;
      vertical-align: .15em;
      font-weight: normal;
      user-select: none;
    }
  }
}
</style>
