<template>
  <header class="container">
    <div class="left home-link" @click="$move('/exam')">
      <div class="logo">
        <img src="@/images/logo_white.png" width="100">
      </div>
    </div>
    <div class="center">
      <h1 class="home-link">{{isGakken ? "試験管理": "CBTテスト管理"}}</h1>
    </div>
    <div class="right" v-if="!noAuth">
      <div>
        <a role="button" target="_blank" href="/download/endpoint/system/manual">
          <span aria-hidden="true" class="material-icons">library_books</span> マニュアル
        </a>
      </div>
      <div class="user">
        <span class="name">
          <span aria-hidden="true" class="material-icons">person</span>
          {{$authInfo.userName}}
        </span>
        <div class="menu">
          <nav>
            <ul>
              <li><span class="icon">chevron_right</span><a @click="$move('/useraccount/password')">パスワード変更</a></li>
              <li v-if="isGakken"><span class="icon">chevron_right</span><a @click="$move('/useraccount/email')">メールアドレス変更</a></li>
            </ul>
            <div class="logout">
              <button v-button @click.prevent="logout">ログアウト</button>
            </div>
          </nav>
        </div>
      </div>
      <a role="button" @click.prevent="logout">
        <span class="material-icons logout">logout</span>
      </a>
    </div>
  </header>
</template>

<script>
import {AuthService} from '@/lib/service';

export default {
  data () {
    return {
      isGakken: process.env.VUE_APP_MODE_GAKKEN === 'true'
    }
  },
  props: {
    noAuth: Boolean
  },
  methods: {
    logout () {
      AuthService.logout();
    },
  }
}
</script>

<style scoped lang="scss">
header {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  margin: 0.5rem auto;
  font-size: 1.4rem;
  color: white;
  @media screen and (max-width: 767px) {
    align-items: baseline;
  }

  .home-link {
    cursor: pointer;
  }
  h1 {
    font-family: 'M PLUS Rounded 1c';
    font-size: 3rem;
    font-weight: normal;
    color: #fff;
    margin-left: 2rem;
    margin-bottom: 20px;

    img {
      vertical-align: middle;
    }
  }

  .right {
    flex: auto;
    text-align: right;
    justify-content: flex-end;
    &> div {
      font-size: 2.2rem;
      display: inline-block;
      margin-left: 1rem;
    }
  }

  .material-icons {
    font-size: 3rem;
    vertical-align: -0.75rem;
    margin-left: 1rem;
  }

  a {
    color: #fff;
    &:hover {
      color: #333;
    }
  }
}

header {

  .user {
    display: inline-block;
    position: relative;
    align-items: center;
    flex-grow: 0;
    &:hover {
      .menu {
        height: auto;
        margin-top: 0;
        opacity: 1;
        overflow: visible;
      }
      .name {
        &:after {
          transform: rotate(180deg);
        }
      }
      nav {
        opacity: 1;
      }
   }
  }
  .name {
    display: inline-block;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 10px 3rem 0 0;
    position: relative;
    span {
      padding: 0 5px;
    }
    &::after {
      content: "expand_more";
      font-family: "Material Icons";
      font-size: 2rem;
      position: absolute;
      right: 0;
      top: 10px;
      transition: all .2s;
      display: inline-block;
    }
  }
  .menu {
    position: absolute;
    width: 180px;
    z-index: 200;
    transition: all .2s;
    top: 100%;
    padding-top: 10px;
    right: -25px;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  nav {
    font-size: 1.4rem;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    position: relative;
    opacity: 0;
    border: 1px solid #f2f2f2;
    &::before {
      display: block;
      position: absolute;
      content: " ";
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #fff transparent;
      left: 50%;
      top: -10px;
      margin-left: -12px;
    }
    .logout {
      display: block;
      margin-top: 1rem;
      text-align: center;
      color: #333;
      button {
        border-radius: 17px;
        padding: 3px 12px;
      }
    }
    box-shadow: 0 0 5px #808080;
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    & > li {
      list-style-type: none;
      text-align: left;
      display: flex;
      align-items: center;
      a {
        color: #333;
        display: inline-block;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        &:hover {
          color: #3951b2;
        }
      }
      .icon {
        display: inline-block;
        font-family: "Material Icons";
        color: #333;
        font-size: 1.8rem;
        padding: 0;
        margin: 0;
      }
    }
  }
  margin: 0.5rem auto;
}
</style>
