export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          processing: false,
        };
      },
      methods: {
        $processing(process) {
          this.processing = true;
          return process().finally(() => {
            this.processing = false;
          });
        },
      },
    });
  },
};
