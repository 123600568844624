<template>
  <UivModal v-model="opened" v-bind="modalAttrs">
    <slot v-if="opened" />
  </UivModal>
</template>

<script>
import {Modal} from 'uiv';
import {config} from '../../service'

export default {
  components: {
    UivModal: Modal
  },
  data() {
    return {
      opened: false
    };
  },
  computed: {
    modalAttrs: function(){
      return Object.assign(
        {},
        config.get('modal.defaultAttrs', {footer: false}),
        this.$attrs
      );
    }
  },
  methods: {
    open(){
      this.opened = true;
    },
    close(){
      this.opened = false;
    }
  }
};
</script>
