export function safeAccess(obj, key) {
  if (key == null || key === "") throw new Error("key is empty");
  let props = key.split(".");
  return safeAccessInner(obj, props);
}

function safeAccessInner(target, props) {
  if (target == null || props.length === 0) return target;
  let prop = props.shift();
  if (prop.match(/^[0-9]+$/)) {
    prop = parseInt(prop, 10);
  }
  return safeAccessInner(target[prop], props);
}
