import {config} from "../service";

export default {
  store(key, value) {
    sessionStorage.setItem(createKey(key), value);
  },
  restore(key) {
    const item = sessionStorage.getItem(createKey(key));
    if (item == null) {
      return null;
    }
    return item;
  },
  remove(key) {
    sessionStorage.removeItem(createKey(key));
  },
};

function createKey(key) {
  return config.get("sessionStorage.prefix", "") + key;
}
