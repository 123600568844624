import {STRATEGIES} from "../OptionsStrategy";
import {ExtEnum} from "../../service";
import isObject from "sugar/object/isObject";
import isString from "sugar/object/isString";

export default {
  props: {
    options: Array,
    optionsStrategy: [Object, String],
    optToInputValue: Function,
    optToLabel: Function,
    optIsSelected: Function,
    optToValue: Function,
    extEnum: [String, Array],
  },
  computed: {
    ops: function () {
      let strategy = Object.assign({}, this.findOptionsStrategy());
      if (this.optToInputValue) {
        strategy.toInputValue = this.optToInputValue;
      }
      if (this.optToLabel) {
        strategy.toLabel = this.optToLabel;
      }
      if (this.optIsSelected) {
        strategy.isSelected = this.optIsSelected;
      }
      if (this.optToValue) {
        strategy.toValue = this.optToValue;
      }
      return strategy;
    },
    _options: function () {
      if (this.options != null) {
        return this.options;
      }
      if (this.extEnum != null) {
        if (isString(this.extEnum)) {
          return ExtEnum[this.extEnum].values();
        } else {
          return this.extEnum;
        }
      }
      return [];
    },
    _inputOptions: function () {
      return (this._options || []).map((option) => {
        return {
          value: this.ops.toInputValue(option),
          label: this.ops.toLabel(option),
          selected: this.ops.isSelected(option, this.value),
        };
      });
    },
  },
  methods: {
    findOptionsStrategy() {
      if (this.optionsStrategy == null) {
        if (this.extEnum == null) {
          return STRATEGIES["default"];
        } else {
          return STRATEGIES["extEnum"];
        }
      } else if (isObject(this.optionsStrategy)) {
        return this.optionsStrategy;
      } else {
        const strategy = STRATEGIES[this.optionsStrategy];
        if (strategy == null)
          throw new Error("unknown OptionsStrategy :" + this.optionsStrategy);
        return strategy;
      }
    },
  },
};
