import {Api, RouteConfigBuilder} from "@/calico-vue/service";
import {getBasePath} from "@/app/menu-tree";

const menuId = "jet.cbt.ta.password";
const basePath = getBasePath(menuId);

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'useraccount' */ "./Index");

const Routes = new RouteConfigBuilder(basePath, {
  title: "パスワード変更",
  menuId,
  basePath,
});

export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  save(form) {
    return api.execute("update", form);
  },
};
