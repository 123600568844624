import {default as move} from "./move";

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        $move: function (path, query = null, hash = null, event = null) {
          return move(this.$router, path, query, hash, event);
        },
      },
    });
  },
};
