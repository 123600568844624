import dasherize from "sugar/string/dasherize";

export { default as config } from "./config/Config";
export { createErrorHandler } from "./misc/DefaultErrorHandler";
export { default as AuthPlugin } from "./auth/AuthPlugin";
export { default as MovePlugin } from "./router/MovePlugin";
export { default as ErrorPlugin } from "./form/ErrorPlugin";
export { default as AnnouncePlugin } from "./announce/AnnouncePlugin";
export { default as ProcessingPlugin } from "./api/ProcessingPlugin";
export { default as ExtEnumPlugin } from "./ext-enum/ExtEnumPlugin";
export { setApiHandlers } from "@/calico-vue/api/Api";
export { setButtonTypes } from "@/calico-vue/ui/directives/button";
export { setTableTypes } from "@/calico-vue/ui/directives/table";
export { loadExtEnum } from "@/calico-vue/ext-enum/ExtEnum";

export const ComponentsPlugin = {
  install(Vue, components = {}) {
    for (const key in components) {
      Vue.component(dasherize(key), components[key]);
    }
  },
};

export const DirectivesPlugin = {
  install(Vue, directives = {}) {
    for (const key in directives) {
      Vue.directive(key, directives[key]);
    }
  },
};

export const FiltersPlugin = {
  install(Vue, filters = {}) {
    for (const key in filters) {
      Vue.filter(key, filters[key]);
    }
  },
};
