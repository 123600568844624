import {config} from "../../service";

const formatter = new Intl.NumberFormat("ja-JP");

function numberFormat(value, prefix, postfix) {
  if (value == null) return null;
  const v = formatter.format(value);
  return (prefix || "") + v + (postfix || "");
}

export function number(value, prefix = null, postfix = null) {
  return numberFormat(value, prefix, postfix);
}

export function unit(value, postfix) {
  return numberFormat(value, null, postfix);
}

export function currency(value, prefix = null, postfix = null) {
  return numberFormat(
    value,
    prefix || config.get("filter.currency.prefix", ""),
    postfix || config.get("filter.currency.postfix", "円")
  );
}
