import dayjs from "dayjs";
import "dayjs/locale/ja";
import utc from "dayjs/plugin/utc";

dayjs.locale("ja");
dayjs.extend(utc);

export default {
  format(value, format) {
    try {
      return dayjs(value).format(format);
    } catch (e) {
      return value;
    }
  },
  utc(value) {
    try {
      return dayjs(value + " 00:00")
        .utc()
        .format();
    } catch (e) {
      return value;
    }
  },
};
