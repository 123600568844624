<template>
  <span class="input-radios" :class="{'has-error': error}" ref="target">
    <label v-for="(option, index) in this._inputOptions" :key="index"
      class="form-check-label"
    >
      <input
        type="radio"
        class="form-check-input"
        :class="inputClass"
        :style="inputStyle"
        v-bind="$attrs"
        v-on="inputListeners"
        :value="option.value"
        :checked="option.selected"
      />
      <span>{{option.label}}</span>
    </label>
    <ErrorTooltip :target="$refs.target" v-bind="errorTooltipAttrs" />
  </span>
</template>

<script>
import InputMixin from './InputMixin'
import OptionsMixin from './OptionsMixin'

export default {
  mixins: [
    InputMixin,
    OptionsMixin
  ],
  props: {
    checkoffEnabled: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    inputListeners: function() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          this.$emit('input', this.ops.toValue(event.target.value, this._options));
        },
        click: event => {
          if(this.checkoffEnabled){
            let val = this.ops.toValue(event.target.value, this._options);
            if(this.ops.valueEquals(val, this.value)){
              this.$emit('input', null);
            }
          }
          this.$emit('click', event);
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
.input-radios {
  display: inline-block;
  border: 1px solid #0000;
  border-radius: .5em;
  &.has-error {
    background-color: #fdd;
    border: solid 1px red;
  }

  label {
    display: inline-block;
    margin: .1em .1em .1em .1em;
    padding: .3em;
    cursor: pointer;
    border: 1px solid #0000;
    border-radius: .5em;
    &:hover {
      border: 1px solid #ddd;
      background-color: #fafafa
    }

    span {
      margin: 0 .2em;
      vertical-align: .15em;
      font-weight: normal;
      user-select: none;
    }
  }
}
</style>
