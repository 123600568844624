<template>
  <span class="input-file" :class="{'has-error': error}">
    <input
      ref="input"
      type="file"
      :class="inputClass"
      :style="inputStyle"
      v-bind="$attrs"
      v-on="inputListeners"
    />
    <ErrorTooltip :target="$refs.input" v-bind="errorTooltipAttrs" />
  </span>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  mixins: [
    InputMixin
  ],
  computed: {
    inputListeners: function() {
      const vm = this;
      return Object.assign({}, this.$listeners, {
        input: event => {
          const files = event.target.files;
          if (files.length === 0) return;
          const file = files[0];
          const reader = new FileReader();
          reader.onload = function() {
            const CHUNK_SIZE = 8184;
            const arrayBuffer = reader.result;
            let result = '';
            for (let i = 0, len = arrayBuffer.byteLength; i < len; i += CHUNK_SIZE) {
              result += btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer, i, Math.min(CHUNK_SIZE, len - i))));
            }
            const media = {
              meta: {
                name: file.name !== '' ? file.name : '新規ファイル',
                size: file.size > 0 ? file.size : 0,
                type: file.type !== '' ? file.type : 'application/octet-stream'
              },
              payload: result
            };
            vm.$emit('input', media);
          };
          reader.readAsArrayBuffer(file);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.input-file {
  display: inline-block;

  input[type=file] {
    padding: 3px;
    margin-right: 5px;
    border-radius: 4px;
  }

  &.has-error input {
    background-color: #fdd;
    border: solid 1px red;
  }
}
</style>
