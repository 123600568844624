import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    examTerms: [],
    currentExamTerm: null,
    registrationToken: null,
    notification: { hidden: true }
  },
  getters: {
    othersites(state) {
      return state.sites.filter(s => s.siteId != state.currentSite.siteId);
    }
  },
  mutations: {
    setExamTerms(state, examTerms) {
      state.examTerms = examTerms;
      if (state.currentExamTerm) {
        const examTermId = state.currentExamTerm.examTermId;
        state.currentExamTerm = state.examTerms.find(exam => exam.examTermId === examTermId);
      } else {
        state.currentExamTerm = examTerms[0];
      }
    },
    setDefaultExamTerm(state, examTermId) {
      state.currentExamTerm = { examTermId };
    },
    chooseExamTerm(state, examTermId) {
       state.currentExamTerm = state.examTerms.find(examTerm => examTerm.examTermId === examTermId);
    },
    setRegistrationToken(state, token) {
      state.registrationToken = token;
    },
    clearRegistrationToken(state) {
      state.registrationToken = null;
    },
    setNotification(state, notification) {
      state.notification = notification;
    },
    clearNotification(state) {
      state.notification = { hidden: true };
    },
    hideNotification(state) {
      state.notification.hidden = true;
    }
  },
  actions: {
    notify (context, notification) {
      const duration = notification.duration == undefined ? 3000 : notification.duration;
      context.commit('setNotification', {
        type: notification.type,
        message: notification.message,
        hidden: false,
        keep: duration > 0
      });
      if (duration > 0) {
        setTimeout(() => {
          context.commit('hideNotification');
        }, duration);
      }
    }
  },
  plugins: [
    createPersistedState({
      key: 'jet-mypage',
      storage: window.sessionStorage
    })
  ]
});
