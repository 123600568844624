<template>
  <span class="input-date" :class="{'has-error': error}">
    <input
      ref="input"
      type="date"
      class="form-control"
      :class="inputClass"
      :style="inputStyle"
      :value="inputValue"
      v-bind="$attrs"
      v-on="inputListeners"
    />
    <span class="glyphicon glyphicon-remove btn-remove" @click="clear()"></span>
    <ErrorTooltip :target="$refs.input" v-bind="errorTooltipAttrs" />
  </span>
</template>

<script>
import InputMixin from './InputMixin'
import {DateUtil} from '../../service';

export default {
  mixins: [
    InputMixin
  ],
  props: {
    toInputValue: Function,
    toValue: Function,
  },
  computed: {
    inputListeners: function() {
      const vm = this;
      return Object.assign({}, this.$listeners, {
        input: event => {
          vm.$emit('input', this._toValue(event.target.value));
        }
      });
    },
    inputValue: function(){
      return this._toInputValue(this.value);
    },
    _toInputValue: function(){
      return this.toInputValue || DEFAULT_FUNC.toInputValue;
    },
    _toValue: function(){
      return this.toValue || DEFAULT_FUNC.toValue;
    },
  },
  methods: {
    clear() {
      this.$emit('input', null)
    }
  }
};

const DEFAULT_FUNC = {
  toInputValue(value) {
    if(value == null) return null;
    return DateUtil.format(value, 'YYYY-MM-DD');
  },
  toValue(inputValue) {
    return inputValue;
  }
};
</script>

<style scoped lang="scss">
.input-date {
  &.has-error input {
    background-color: #fdd;
    border: solid 1px red;
  }

  input[type="date"] {
    width: 150px;
    display: inline;
    &::-webkit-calendar-picker-indicator {
      margin-inline-start: 0;
      margin-inline-end: 12px;
    }
  }

  .btn-remove {
    color: #999;
    margin-left: -25px;
    margin-right: 10px;
    &:hover {
      color: #ccc;
    }
  }
}
</style>
