import {config} from "../service";
import axios from "axios";
import isObject from "sugar/object/isObject";
import isFunction from "sugar/object/isFunction";
import isArray from "sugar/object/isArray";
import isString from "sugar/object/isString";

export class Api {
  constructor(basePath = null) {
    this.basePath = basePath;
  }

  execute(path, params = null, handler = null) {
    if (params == null) {
      params = {};
    }
    const url = createUrl(path, this.basePath);
    let promise = getHttpClient().post(url, params);
    if (handler !== false) {
      //handlerがfalseの場合は何もしない
      promise = applyHandler(HANDLERS.default, promise);
      promise = applyHandler(handler, promise);
    }
    return promise;
  }
}

function createUrl(path, basePath) {
  let array = [];
  const rootPath = config.get("api.rootPath", "/endpoint");
  if (rootPath) {
    array.push(rootPath);
  }
  if (basePath != null && !path.startsWith("/")) {
    array.push(basePath);
    path = "/" + path;
  }
  array.push(path);
  return array.join("");
}

function getHttpClient() {
  return axios.create({
    withCredentials: true,
  });
}

function applyHandler(handler, promise) {
  if (isString(handler)) {
    handler = HANDLERS[handler];
  }
  if (handler == null || !isObject(handler)) {
    return promise;
  }

  for (const handle of getHandles(handler.then)) {
    promise = promise.then(handle);
  }
  for (const handle of getHandles(handler.catch)) {
    promise = promise.catch(handle);
  }
  for (const handle of getHandles(handler.finally)) {
    promise = promise.finally(handle);
  }
  return promise;
}
function getHandles(handles) {
  if (handles == null) {
    return [];
  } else if (isArray(handles)) {
    return handles;
  } else if (isFunction(handles)) {
    return [handles];
  } else {
    return [];
  }
}

/**
 * Handlers
 */
export let HANDLERS = {
  default: {
    then: [(res) => res.data],
    catch: [
      (err) => {
        console.warn(err);
        throw err;
      },
    ],
    finally: [],
  },
};

export function setApiHandlers(handlers) {
  HANDLERS = handlers;
}
