// https://uiv.wxsm.space/components/notification.html#props

export const ANNOUNCE_TYPES = {
  default: {
    type: undefined,
    title: undefined,
    content: undefined,
    html: false,
    glyphicon: undefined,
    duration: 3000,
    placement: undefined,
    customClass: undefined,
    offset: undefined,
    offsetX: undefined,
    offsetY: undefined,
  },
  info: {
    type: "info",
  },
  success: {
    type: "success",
  },
  warning: {
    type: "warning",
  },
  danger: {
    type: "danger",
  },
  error: {
    type: "danger",
  },
  saved: {
    type: "success",
    title: "保存しました。",
    glyphicon: "saved",
  },
  deleted: {
    type: "success",
    title: "削除しました。",
    glyphicon: "trash",
  },
  executed: {
    type: "success",
    title: "実行しました。",
    glyphicon: "flash",
  },
  sent: {
    type: "success",
    title: "送信しました。",
    glyphicon: "saved",
  },
};
