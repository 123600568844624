import {ExtEnum} from "./ExtEnum";

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $ExtEnum: () => ExtEnum,
      },
    });
  },
};
