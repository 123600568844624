<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="col-sm-12 login-right-contents">
          <div class="login-inner">
            <img src="@/images/logo.png" width="130">
            <h1 class="text-nowrap">{{ isGakken ? "試験管理" : "CBTテスト管理" }} ログイン</h1>
            <form v-form>
              <div class="form-group">
                <label for="loginId">メールアドレス</label>
                <input-text v-model="form.loginId" :error="$error('loginId')"
                  inputClass="mb-1"
                  :default-focus="true" />
                <label for="passowrd">パスワード</label>
                <input-password v-model="form.password" :error="$error('password')"
                  inputClass="mb-2" />
              </div>
              <div class="text-center"><span @click="login" class="btn btn-primary font-weight-bold">ログイン</span></div>
            </form>
            <div class="password-reset">
              <a @click="$move('password/')">パスワードを忘れた方はこちら</a>
            </div>
            <div class="signup" v-if="isGakken">
              <a @click="$move('signup/')">アカウント未登録の方はこちら</a>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <footer class="footer">
            <p>© 2022 JET Committee.</p>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AuthService} from '@/lib/service';

export default {
  data () {
    return {
      form: {
        loginId: null,
        password: null,
      },
      isGakken: process.env.VUE_APP_MODE_GAKKEN === 'true',
      error: null,
    }
  },
  mounted() {
    document.body.classList.add('login')
  },
  methods: {
    login () {
      AuthService.login(this.form)
          .then(() => this.$move('/exam'))
          .catch(error => this.error = error);
    },
    onFieldError(name, error) {
      this.error = this.error ? this.error : {};
      this.error[name] = error;
    }
  }
};
</script>
