<template>
  <span class="input-checkbox" :class="{'has-error': error}" ref="target">
    <label class="form-check-label">
      <input
        ref="input"
        type="checkbox"
        class="form-check-input"
        :class="inputClass"
        :style="inputStyle"
        v-bind="$attrs"
        v-on="inputListeners"
        :checked="checked"
      />
      <span>{{label}}</span>
    </label>
    <ErrorTooltip :target="$refs.target" v-bind="errorTooltipAttrs" />
  </span>
</template>

<script>
import InputMixin from './InputMixin'
import isEqual from 'sugar/object/isEqual';

export default {
  mixins: [
    InputMixin
  ],
  props: {
    label: String,
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
  },
  computed: {
    inputListeners: function() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          this.$emit('input', event.target.checked ? this.trueValue : this.falseValue);
        }
      });
    },
    checked: function(){
      return isEqual(this.value, this.trueValue);
    }
  }
};
</script>

<style scoped lang="scss">
.input-checkbox {
  display: inline-block;
  border: 1px solid #0000;
  border-radius: .5em;
  &.has-error {
    background-color: #fdd;
    border: solid 1px red;
  }

  label {
    display: inline-block;
    margin: .1em .1em .1em .1em;
    padding: .3em;
    cursor: pointer;
    border: 1px solid #0000;
    border-radius: .5em;
    &:hover {
      border: 1px solid #ddd;
      background-color: #fafafa
    }

    span {
      margin: 0 .2em;
      vertical-align: .15em;
      font-weight: normal;
      user-select: none;
    }
  }
}
</style>
