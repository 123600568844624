<template>
  <img v-if="media"
    :src="src"
    v-bind="$attrs"
    v-on="$listeners"/>
</template>

<script>
export default {
  props: {
    media: Object,
  },
  computed: {
    src: function(){
      return 'data:' + this.media.meta.type + ';base64,' + this.media.payload;
    }
  },
};
</script>
