import {config, SerializeService, SessionStorageService} from "../service";

export default {
  forDefault,
  forModal,
  restoreSearchFormFromSessionStorage,
};

/**
 * Config
 */
const DEFAULT_CONFIG = {
  api: {
    getSearchForm: null,
    search: null,
  },
  formProp: "form",
  resultProp: "result",
  errorProp: "error",
  eager: false,
};

/**
 * SearchContext
 */
class SearchContext {
  constructor(vm, config) {
    this.vm = vm;
    this.config = config;

    //api check
    if (this.config.api.getSearchForm == null)
      throw new Error("config.api.getSearchForm is not defined");
    if (this.config.api.search == null)
      throw new Error("config.api.search is not defined");
  }

  /**
   * Getter & Setter
   */
  getForm() {
    return this.vm[this.config.formProp];
  }
  setForm(form) {
    this.vm[this.config.formProp] = form;
  }
  getResult() {
    return this.vm[this.config.resultProp];
  }
  setResult(result) {
    this.vm[this.config.resultProp] = result;
  }
  getError() {
    return this.vm[this.config.errorProp];
  }
  setError(error) {
    this.vm[this.config.errorProp] = error;
  }

  /**
   * Handiling
   */
  init() {
    let form = this.restoreForm();
    if (form == null) {
      this.getDefaultForm().then(
        () => {
          if (this.config.eager) {
            removeSearchFormFromSessionStorage(this.vm.$route);
            this.executeSearch();
          }
        },
        () => {}
      );
    } else {
      this.setForm(form);
      this.executeSearch();
    }
    return this;
  }

  process() {
    this.storeForm();
  }

  reload() {
    if (this.getForm() != null && this.getResult() != null) {
      this.executeSearch();
    }
  }

  /**
   * EventHandler
   */
  onSearch() {
    let form = this.getForm();
    if (form._page) {
      form._page.no = 1;
    }
    this.process();
  }

  onPageNoChanged(pageNo) {
    let form = this.getForm();
    if (form == null || form._page == null) {
      return;
    }
    form._page.no = pageNo;
    this.process();
  }

  onPerPageChanged(perPage) {
    let form = this.getForm();
    if (form == null || form._page == null) {
      return;
    }
    form._page.perPage = perPage;
    form._page.no = 1;
    this.process();
  }

  onSortChanged(sortProp) {
    let form = this.getForm();
    if (form == null || form._sort == null) {
      return;
    }
    if (form._sort.prop === sortProp) {
      form._sort.type = form._sort.type === "ASC" ? "DESC" : "ASC";
    } else {
      form._sort = { prop: sortProp, type: "ASC" };
    }
    if (form._page != null) {
      form._page.no = 1;
    }
    this.process();
  }

  /**
   * Inner Method
   */
  getDefaultForm() {
    this.setForm(null);
    return this.config.api.getSearchForm().then((data) => {
      this.setForm(data);
    });
  }

  executeSearch() {
    this.setResult(null);
    let form = this.getForm();
    let f = () => {
      return this.config.api.search(form).then(
        (data) => {
          this.setResult(data);
        },
        (error) => {
          this.setError(error);
        }
      );
    };
    this.vm.$processing != null ? this.vm.$processing(f) : f();
  }

  storeForm() {
    let form = this.getForm();
    if (form == null) return;
    const $route = this.vm.$route;
    const raw = SerializeService.serialize(form);
    storeSearchFormToSessionStorage($route, raw);
    this.vm.$move($route.path, $route.query, raw, "dummy-event");
  }

  restoreForm() {
    var raw = this.vm.$route.hash;
    if (raw != null && raw.startsWith("#")) {
      raw = raw.replace(/^#/, "");
    }
    return SerializeService.deserialize(raw);
  }
}

/**
 * SearchContextForModal
 */
class SearchContextForModal extends SearchContext {
  init() {
    this.getDefaultForm().then(
      () => {
        if (this.config.eager) {
          this.executeSearch();
        }
      },
      () => {}
    );
    return this;
  }

  process() {
    this.executeSearch();
  }
}

/**
 * SessionStorage
 */
function createSessionStorageKey(path) {
  return [config.get("sessionStorage.searchFormKey", "search-form"), path].join(
    "-"
  );
}
function storeSearchFormToSessionStorage(route, raw) {
  let path = route.path;
  let key = createSessionStorageKey(path);
  SessionStorageService.store(key, raw);
}

function restoreSearchFormFromSessionStorage(route) {
  let path = route.path;
  let key = createSessionStorageKey(path);
  return SessionStorageService.restore(key);
}

function removeSearchFormFromSessionStorage(route) {
  let path = route.path;
  let key = createSessionStorageKey(path);
  return SessionStorageService.remove(key);
}

/**
 * Factory Method
 */
function forDefault(vm, config) {
  return new SearchContext(vm, Object.assign({}, DEFAULT_CONFIG, config || {}));
}
function forModal(vm, config) {
  return new SearchContextForModal(
    vm,
    Object.assign({}, DEFAULT_CONFIG, config || {})
  );
}
