export const TREE = [
  {
    name: "試験管理",
    icon: "edit_square",
    menuId: "jet.cbt.ta.exam",
    requiredAuthorities: [
      "jet.cbt.ta.exam.read",
      "jet.cbt.ta.examinee.read",
      "jet.cbt.ta.examinee.write",
    ],
    basePath: "/exam",
  },
  {
    name: "パスワード変更",
    icon: "key",
    menuId: "jet.cbt.ta.password",
    requiredAuthorities: ["jet.cbt.ta.self.write"],
    basePath: "/useraccount/password",
  },
  {
    name: "メールアドレス変更",
    icon: "email",
    menuId: "jet.cbt.ta.email",
    requiredAuthorities: ["jet.cbt.ta.self.write"],
    basePath: "/useraccount/email",
  },
];

/**
 * basePath取得
 */
export function getBasePath(menuId) {
  if (basePathCache == null) {
    basePathCache = {};
    gatherBasePathToCache(TREE);
  }
  if (basePathCache[menuId] == null) {
    throw new Error("basePath for " + menuId + " is not defined");
  }
  return basePathCache[menuId];
}

// メニューIDからアイコンをもらう
export function getMenuIcon(menuId) {
  if (menuIconCache == null) {
    menuIconCache = {};
    const stack = [];
    gatherIconToCache(TREE, stack);
  }
  if (menuIconCache[menuId] == null) {
    throw new Error("icon for " + menuId + " is not defined ");
  }
  return menuIconCache[menuId];
}

// メニューIDから権限をもらう
export function getRequiredAuthorities(menuId) {
  if (authoritiesCache == null) {
    authoritiesCache = {};
    gatherAuthoritiesToCache(TREE);
  }
  if (authoritiesCache[menuId] == null) {
    return [];
  }
  return authoritiesCache[menuId];
}

let menuIconCache = null;

function gatherIconToCache(nodes, stack) {
  nodes.forEach((node) => {
    if (node.icon != null) {
      menuIconCache[node.menuId] = node.icon;
      if (node.children != null) {
        stack.push(node);
        gatherIconToCache(node.children, stack);
        stack.pop();
      }
    } else if (node.menuId != null) {
      for (let i = stack.length - 1; i >= 0; i--) {
        const n = stack[i];
        if (n.icon != null) {
          menuIconCache[node.menuId] = n.icon;
          break;
        }
      }
    }
  });
}

let basePathCache = null;

function gatherBasePathToCache(nodes) {
  nodes.forEach((node) => {
    if (node.menuId != null && node.basePath != null) {
      basePathCache[node.menuId] = node.basePath;
    }
    if (node.children != null) {
      gatherBasePathToCache(node.children);
    }
  });
}

let authoritiesCache = null;

function gatherAuthoritiesToCache(nodes) {
  nodes.forEach((node) => {
    if (node.menuId != null && node.requiredAuthorities != null) {
      authoritiesCache[node.menuId] = node.requiredAuthorities;
    }
    if (node.children != null) {
      gatherAuthoritiesToCache(node.children);
    }
  });
}
