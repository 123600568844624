<template>
  <div>
    お探しのページが見つかりません。
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
div {
  padding: 1.5em;
  font-size: 1.2em;
}
</style>
