import * as LZString from "lz-string";
import JSON from "json-bigint";

export default {
  serialize(obj) {
    let json = JSON.stringify(obj);
    return LZString.compressToEncodedURIComponent(json);
  },
  deserialize(raw) {
    if (raw == null) return null;
    try {
      let json = LZString.decompressFromEncodedURIComponent(raw);
      return JSON.parse(json);
    } catch (e) {
      console.warn(e, raw);
      return null;
    }
  },
};
