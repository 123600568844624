<template>
  <div class="clc-pager" :class="{'pull-right': pullRight}">
    検索結果 <span class="result-count">{{resultCount}}</span> 件
    <select v-model="perPage" @change="searchContext.onPerPageChanged(perPage)" class="form-control">
      <option v-for="pp in perPageOptions" :key="pp" :value="pp">
        {{pp}} 件
      </option>
    </select>
    <Pagination v-model="currentPage"
      class="uiv-pagination"
      :total-page="totalPage"
      :max-size="10"
      @change="searchContext.onPageNoChanged(currentPage)" />
  </div>
</template>

<script>
import {Pagination} from 'uiv'

export default {
  components: {
    Pagination
  },
  props: {
    searchContext: Object,
    perPageOptions: {
      type: Array,
      default: () => [10, 50, 100]
    },
    pullRight: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    form: function(){
      return this.searchContext.getForm();
    },
    result: function(){
      return this.searchContext.getResult();
    },
    resultCount: function(){
      if(this.result == null) return null;
      return this.result._count;
    },
    totalPage: function() {
      if(this.resultCount == null || this.perPage == null) return null;
      return Math.ceil(this.resultCount / this.perPage);
    }
  },
  watch: {
    'form._page.no': function(pageNo){
      this.currentPage = pageNo;
    },
    'form._page.perPage': function(perPage){
      this.perPage = perPage;
    }
  },
  data() {
    return {
      currentPage: null,
      perPage: null,
    };
  },
  created(){
    if(this.form != null && this.form._page != null){
      this.perPage = this.form._page.perPage;
      this.currentPage = this.form._page.no;
    }
  },
};
</script>

<style scoped lang="scss">
.clc-pager {
  display: inline-block;
  margin: 0 1em;

  .result-count {
    font-size: 140%;
    color: #15bc95;
    margin: 0 4px;
  }
  select {
    margin: 0 4px;
    width: auto;
  }
  .uiv-pagination {
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px;
  }
  ::v-deep .pagination > li > a {
    color: #199f99;
  }
  ::v-deep .pagination > .active > a {
    color: #fff;
    background-color: #15bc95;
    border-color: #15bc95;
  }
}
</style>
