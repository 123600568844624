<template>
  <section class="title-area">
    <span @click="moveToBasePath($event)" class="material-icons icon">{{icon}}</span>{{title}}
  </section>
</template>

<script>
import {getBasePath, getMenuIcon} from '@/app/menu-tree';

export default {
  computed: {
    title: vm => {
      let meta = vm.$route.meta;
      if (meta.title != null) return meta.title;
      return null;
    },
    basePath: vm => {
      let meta = vm.$route.meta;
      if (meta.basePath != null) return meta.basePath;
      if (meta.menuId != null) {
        let basePath = getBasePath(meta.menuId);
        if (basePath != null) return basePath;
      }
      return null;
    },
    icon: vm => {
      let meta = vm.$route.meta;
      console.log(meta);
      if (meta.icon != null) return meta.icon;
      if (meta.menuId != null) {
        let icon = getMenuIcon(meta.menuId);
        if (icon != null) return icon;
      }
      return null;
    },
  },
  methods: {
    moveToBasePath(event) {
      if (this.basePath != null) {
        console.log(this.basePath);
        this.$move(this.basePath, event);
      }
    }
  }
}
</script>
