<template>
  <div>
    <MainHeader :no-auth="noAuth"/>
    <div class="col-xs-12">
      <div class="container mb-3">
        <div class="col-sm-12 col-md-12 main">
          <PageHeader :title="title" :icon="icon" v-if="!noAuth"/>
          <slot></slot>
        </div>
      </div>
      <footer>
        <div class="inner">
          <p>© 2022 JET Committee.</p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import MainHeader from './elements/MainHeader';
import PageHeader from './elements/PageHeader';

export default {
  components: {
    MainHeader,
    PageHeader,
  },
  props: {
    title: { type: String, default: null },
    icon: { type: String, default: null },
    noAuth: { type: Boolean, default: false }
  },
  computed: {
    mainStyles: vm => {
      return {
        paddingLeft: vm.showAsideLeft ? '300px' : '0',
        minHeight: vm.mainHeight,
      }
    },
    asideLeftStyles: vm => {
      return {
        left: vm.showAsideLeft ? '0' : '-300px'
      }
    }
  },
  data(){
    return {
      mainHeight: calcMainHeight(),
    };
  },
  methods: {
    updateMainHeight(){
      this.mainHeight = calcMainHeight();
    },
  },
  mounted: function () {
    window.addEventListener('resize', this.updateMainHeight);
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateMainHeight);
  },
}

/**
 * メイン領域height計算
 */
function calcMainHeight(){
  return (window.innerHeight - 30) + 'px';
}
</script>
